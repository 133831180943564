import * as React from "react";
import { Trans } from "gatsby-plugin-react-i18next";

const Careers = () => {
  return (
    <>
      <section id="careers" className="relative text-navy-blue">
        <div className="bg-bottom-right pb-skew"></div>
        <div className="relative pb-skew">
          <div className="container w-full">
            <p className="subheading">
              <Trans>Join us</Trans>
            </p>
            <h2 className="heading-2">
              <Trans>Careers</Trans>
              <span></span>
            </h2>
            <div className="flex flex-col lg:flex-row mt-40">
              <div className="lg:w-2/4 pr-30 shrink-0 mb-50 lg:mb-0">
                <p className="lg:mt-75 text-32 font-medium md:text-48 md:!leading-60">
                  <Trans>We work with the most talented & creative professionals worldwide.</Trans>
                  <span className="block mt-20 text-20 font-normal">
                    <Trans>Leaders, entrepreneurs, and top-edge developers globally.</Trans>
                  </span>
                </p>
                <a
                  href="mailto:hiring@dcspark.io"
                  rel="noopener noreferrer"
                  className="btn-big mt-35"
                >
                  <Trans>Are you one of us? Join us</Trans>
                  <span className="arrow">
                    <img
                      className="w-full h-full object-scale-down"
                      width="16"
                      height="16"
                      src="/images/icons/rarr-white.svg"
                      alt="right-arrow"
                    />
                  </span>
                </a>
              </div>
              <aside className="w-full p-25 md:p-35 border border-grey-border-dark rounded-2xl">
                <p className="text-20">
                  <Trans>
                    High-quality work & global mindset are the main drivers of our people. We are
                    building and thinking about the future of tech.
                  </Trans>
                </p>
                <ul className="-my-15 mt-15 md:mt-20">
                  <li className="flex items-start py-15">
                    <div className="mr-25 md:mr-30 w-40 h-40 shrink-0">
                      <img
                        className="w-full h-full object-scale-down"
                        width="40"
                        height="40"
                        src="/images/icons/benefits.svg"
                        alt="benefits"
                      />
                    </div>
                    <div>
                      <h3 className="text-18 font-medium">
                        <Trans>Competitive benefits</Trans>
                      </h3>
                      <p className="mt-2">
                        <Trans>We pay excellent, we ask for excellency.</Trans>
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start py-15">
                    <div className="mr-25 md:mr-30 w-40 h-40 shrink-0">
                      <img
                        className="w-full h-full object-scale-down"
                        width="40"
                        height="40"
                        src="/images/icons/world.svg"
                        alt="world"
                      />
                    </div>
                    <div>
                      <h3 className="text-18 font-medium">
                        <Trans>Remote first</Trans>
                      </h3>
                      <p className="mt-2">
                        <Trans>We hire the best, no matter where.</Trans>
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start py-15">
                    <div className="mr-25 md:mr-30 w-40 h-40 shrink-0">
                      <img
                        className="w-full h-full object-scale-down"
                        width="40"
                        height="40"
                        src="/images/icons/time.svg"
                        alt="time"
                      />
                    </div>
                    <div>
                      <h3 className="text-18 font-medium">
                        <Trans>Flexible working hours</Trans>
                      </h3>
                      <p className="mt-2">
                        <Trans>
                          You are the best in your field, you manage and coordinate your time.
                        </Trans>
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start py-15">
                    <div className="mr-25 md:mr-30 w-40 h-40 shrink-0">
                      <img
                        className="w-full h-full object-scale-down"
                        width="40"
                        height="40"
                        src="/images/icons/ship.svg"
                        alt="ship"
                      />
                    </div>
                    <div>
                      <h3 className="text-18 font-medium">
                        <Trans>Flexible holidays</Trans>
                      </h3>
                      <p className="mt-2">
                        <Trans>
                          We value rest and enjoyment of life. We respect local holidays!
                        </Trans>
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start py-15">
                    <div className="mr-25 md:mr-30 w-40 h-40 shrink-0">
                      <img
                        className="w-full h-full object-scale-down"
                        width="40"
                        height="40"
                        src="/images/icons/study.svg"
                        alt="study"
                      />
                    </div>
                    <div>
                      <h3 className="text-18 font-medium">
                        <Trans>Learning & opportunities</Trans>
                      </h3>
                      <p className="mt-2">
                        <Trans>
                          We are the creators of the future, we are in constant learning.
                        </Trans>
                      </p>
                    </div>
                  </li>
                </ul>
              </aside>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Careers;
