import * as React from "react";
import { Trans } from "gatsby-plugin-react-i18next";

const Investors = () => {
  return (
    <>
      <section id="investors" className="relative text-navy-blue">
        <div className="bg-bottom-right pb-skew"></div>
        <div className="relative pb-skew">
          <div className="container">
            <p className="subheading">
              <Trans>Companies that believe in us</Trans>
            </p>
            <h2 className="heading-2 ">
              <Trans>Investors</Trans>
              <span></span>
            </h2>
            <div className="mt-40">
              <ul className="investor-list">
                <li className="investor">
                  <a
                    className="block w-full h-full"
                    href="https://archetype.fund/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="w-full h-full object-scale-down"
                      width="214"
                      height="60"
                      srcSet="/images/investors/1@2x.png 2x, /images/investors/1.png 1x"
                      src="/images/investors/1.png"
                      alt="archetype_ventures"
                    />
                  </a>
                </li>
                <li className="investor">
                  <a
                    className="block w-full h-full"
                    href="https://assembly.capital"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="w-full h-full object-scale-down"
                      width="214"
                      height="60"
                      srcSet="/images/investors/2@2x.png 2x, /images/investors/2.png 1x"
                      src="/images/investors/2.png"
                      alt="assembly"
                    />
                  </a>
                </li>
                <li className="investor">
                  <a
                    className="block w-full h-full"
                    href="https://sosv.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="w-full h-full object-scale-down"
                      width="214"
                      height="60"
                      srcSet="/images/investors/3@2x.png 2x, /images/investors/3.png 1x"
                      src="/images/investors/3.png"
                      alt="sosv"
                    />
                  </a>
                </li>
                <li className="investor">
                  <a
                    className="block w-full h-full"
                    href="http://wavegp.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="w-full h-full object-scale-down"
                      width="214"
                      height="60"
                      srcSet="/images/investors/4@2x.png 2x, /images/investors/4.png 1x"
                      src="/images/investors/4.png"
                      alt="wave"
                    />
                  </a>
                </li>
                <li className="investor">
                  <a
                    className="block w-full h-full"
                    href="https://www.graphventures.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="w-full h-full object-scale-down"
                      width="214"
                      height="60"
                      srcSet="/images/investors/5@2x.png 2x, /images/investors/5.png 1x"
                      src="/images/investors/5.png"
                      alt="graph"
                    />
                  </a>
                </li>
                <li className="investor">
                  <a
                    className="block w-full h-full"
                    href="https://www.p23.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="w-full h-full object-scale-down"
                      width="214"
                      height="60"
                      srcSet="/images/investors/6@2x.png 2x, /images/investors/6.png 1x"
                      src="/images/investors/6.png"
                      alt="project23"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Investors;
