import * as React from "react";
import { Trans } from "gatsby-plugin-react-i18next";

const Founders = () => {
  return (
    <>
      {/* Founders */}
      <section id="founders" className="tl-wrapper relative overflow-hidden">
        <div className="pt-skew absolute top-0 left-0 right-0 bottom-0">
          <div className="bg-grey-founders w-full h-full"></div>
        </div>
        <div className="bg-top-left pt-skew"></div>
        <div className="relative pt-skew">
          <div className="container relative text-navy-blue">
            <div className="w-full">
              <p className="subheading">
                <Trans>People behind dcSpark</Trans>
              </p>
              <h2 className="heading-2">
                <Trans>Meet the team</Trans>
                <span></span>
              </h2>
              <ul className="mt-40 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-16">
                {[
                  {
                    name: "Nicolas Arqueros",
                    role: "Co-founder and CEO",
                    photo: "/images/team/nicolas_arqueros.webp",
                    githubUrl: "https://github.com/nicarq",
                    twitterUrl: "https://twitter.com/nicoarqueros",
                    linkedinUrl: "https://www.linkedin.com/in/nicoarqueros/",
                  },
                  {
                    name: "Sebastien Guillemot",
                    role: "Co-founder and CTO",
                    photo: "/images/team/sebastien_guillemot.webp",
                    githubUrl: "https://github.com/sebastienGllmt",
                    twitterUrl: "https://twitter.com/SebastienGllmt",
                    linkedinUrl: "https://linkedin.com/in/sebastiengllmt",
                  },
                  {
                    name: "Sarah Weaver",
                    role: "People & Process Specialist",
                    photo: "/images/team/sarah.webp",
                    linkedinUrl: "https://www.linkedin.com/in/sarah-weaver1/",
                  },
                  {
                    name: "Paul Ccari",
                    role: "Design Engineer",
                    photo: "/images/team/paul.webp",
                    linkedinUrl: "https://www.linkedin.com/in/paulccari/",
                    twitterUrl: "https://x.com/paulclindo",
                    githubUrl: "https://github.com/paulclindo",
                  },
                  {
                    name: "Rob",
                    role: "R&D Engineer/Programmer",
                    photo: "/images/team/rob.webp",
                  },
                  {
                    name: "Eddie Alvarado",
                    role: "Staff Engineer",
                    photo: "/images/team/eddie.webp",
                  },
                  {
                    name: "Alfredo Gallardo",
                    role: "Staff Engineer",
                    photo: "/images/team/alfredo.webp",
                  },
                  {
                    name: "Las Safin",
                    role: "Cryptography Engineer'",
                    photo: "/images/team/las-safin.webp",
                  },
                ].map((member) => (
                  <li className="team-member">
                    <div className="card">
                      <div className="team-member__image">
                        <img
                          className="w-full h-full object-cover"
                          width="209"
                          height="256"
                          src={member.photo}
                          alt={member.name}
                        />
                      </div>
                      <p className="team-member__name">
                        {member.name}
                        <span className="block mt-5 font-normal font-base text-16 text-grey-p-btn">
                          {member.role}
                        </span>
                      </p>
                      <ul className="social-list">
                        {member.githubUrl && (
                          <li className="px-7">
                            <a
                              href={member.githubUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              title="Github"
                              className="social-list__link group"
                            >
                              <svg
                                className="fill-current group-hover:text-white"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M8 0C3.58 0 0 3.57878 0 7.99728C0 11.5361 2.29 14.5251 5.47 15.5847C5.87 15.6547 6.02 15.4147 6.02 15.2048C6.02 15.0149 6.01 14.3851 6.01 13.7153C4 14.0852 3.48 13.2255 3.32 12.7756C3.23 12.5457 2.84 11.836 2.5 11.646C2.22 11.4961 1.82 11.1262 2.49 11.1162C3.12 11.1062 3.57 11.696 3.72 11.9359C4.44 13.1455 5.59 12.8056 6.05 12.5957C6.12 12.0759 6.33 11.726 6.56 11.5261C4.78 11.3261 2.91937 10.6358 2.91937 7.57742C2.91937 6.70771 3.22938 5.98796 3.73938 5.42815C3.65938 5.22822 3.38 4.4085 3.81938 3.30887C3.81938 3.30887 4.48937 3.09894 6.01937 4.12859C7.32841 3.76871 8.71034 3.76871 10.0194 4.12859C11.5494 3.08895 12.2194 3.30887 12.2194 3.30887C12.6594 4.4085 12.3794 5.22822 12.2994 5.42815C12.8094 5.98796 13.1194 6.69772 13.1194 7.57742C13.1194 10.6464 11.2494 11.3261 9.46937 11.5261C9.75937 11.776 10.0094 12.2558 10.0094 13.0056C10.0094 14.0752 9.99938 14.9349 9.99938 15.2048C9.99938 15.4147 10.1494 15.6647 10.5494 15.5847C13.71 14.5251 16 11.5261 16 7.99728C16 3.57878 12.42 0 8 0Z"
                                />
                              </svg>
                              <span className="hidden">Github</span>
                            </a>
                          </li>
                        )}
                        {member.twitterUrl && (
                          <li className="px-7">
                            <a
                              href={member.twitterUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              title="Twitter"
                              className="group social-list__link"
                            >
                              <svg
                                className="fill-current group-hover:text-white"
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0.999756 12.6387C2.34868 13.4982 3.95096 14 5.67225 14C11.3315 14 14.5288 9.2492 14.336 4.98821C14.9315 4.56153 15.4484 4.02818 15.8569 3.42009C15.31 3.661 14.7226 3.82391 14.1064 3.89684C14.7354 3.52277 15.2188 2.92883 15.4462 2.22096C14.8573 2.56818 14.205 2.82034 13.511 2.9564C12.955 2.36754 12.1628 2 11.2859 2C9.31854 2 7.87251 3.82464 8.3168 5.71966C5.7836 5.5934 3.53734 4.38737 2.03436 2.55403C1.23559 3.91607 1.62001 5.69753 2.97769 6.59914C2.47791 6.58354 2.00734 6.44676 1.59664 6.21999C1.56378 7.62375 2.57539 8.93608 4.0415 9.22888C3.61218 9.34498 3.14234 9.37111 2.66519 9.2804C3.05253 10.4846 4.1784 11.3597 5.51235 11.3844C4.23133 12.3829 2.617 12.8284 0.999756 12.6387Z"
                                />
                              </svg>
                              <span className="hidden">Twitter</span>
                            </a>
                          </li>
                        )}
                        {member.linkedinUrl && (
                          <li className="px-7">
                            <a
                              href={member.linkedinUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              title="LinkedIn"
                              className="group social-list__link"
                            >
                              <svg
                                className="fill-current group-hover:text-white"
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M4.11244 15.0004H1.12024V5.2527H4.11244V15.0004ZM2.61806 4.00283C1.53935 4.00283 1 3.50242 1 2.5016C1 2.08497 1.15459 1.73032 1.46721 1.43765C1.77983 1.14498 2.16115 1.00037 2.61806 1.00037C3.69676 1.00037 4.23612 1.50307 4.23612 2.5016C4.23612 3.50012 3.69676 4.00283 2.61806 4.00283ZM15.2121 15.0004H12.1581V9.56357C12.1581 8.14842 11.6565 7.43913 10.6603 7.43913C9.87012 7.43913 9.35024 7.83509 9.1006 8.62703C9.02159 8.75098 8.97693 9.00233 8.97693 9.37764V15.0004H5.92288V8.37567C5.92288 6.87444 5.89884 5.8346 5.86105 5.2527H8.4788L8.66431 6.56456C9.35138 5.52127 10.3476 5.00135 11.7184 5.00135C12.7593 5.00135 13.6009 5.36518 14.2433 6.09284C14.8892 6.8228 15.2121 7.87297 15.2121 9.25024V15.0004Z" />
                              </svg>
                              <span className="hidden">LinkedIn</span>
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {/* Core values */}
            <p className="hr-custom">
              <Trans>
                <strong>+30</strong> awesome team members
              </Trans>
            </p>

            <div className="bg-white w-full rounded-lg p-25 md:p-40 md:pt-80 lg:p-80 text-navy-blue">
              <h2 className="heading-2">
                <Trans>Our Core values</Trans>
                <span></span>
              </h2>
              <ul className="values md:mt-10 -mx-40 flex flex-wrap">
                <li className="lg:w-2/4 px-40 mt-50 md:mt-70">
                  <h3 className=" mb-20 text-24 font-bold font-heading">
                    <Trans>Ownership</Trans>
                  </h3>
                  <p className="leading-relaxed">
                    <Trans>
                      We are <strong>high performers.</strong> We take
                      responsibility for our work and execute our tasks from
                      start to finish. We seek additional opportunities to
                      excel.
                    </Trans>
                  </p>
                </li>
                <li className="lg:w-2/4 px-40 mt-50 md:mt-70">
                  <h3 className=" mb-20 text-24 font-bold font-heading">
                    <Trans>Curiosity</Trans>
                  </h3>
                  <p className="leading-relaxed">
                    <Trans>
                      We dig deeper in order to{" "}
                      <strong>understand how and why things work.</strong>{" "}
                      Rather than take what’s in front of us at face value, we
                      seek to understand the underlying mechanics at play so
                      that we can seamlessly convert that curiosity into novel
                      innovation.
                    </Trans>
                  </p>
                </li>
                <li className="lg:w-2/4 px-40 mt-50 md:mt-70">
                  <h3 className=" mb-20 text-24 font-bold font-heading">
                    <Trans>Transparency</Trans>
                  </h3>
                  <p className="leading-relaxed">
                    <Trans>
                      We <strong>encourage</strong> direct & honest talk. We are{" "}
                      <strong>bold.</strong> We discuss things openly and with
                      candor.
                    </Trans>
                  </p>
                </li>
                <li className="lg:w-2/4 px-40 mt-50 md:mt-70">
                  <h3 className=" mb-20 text-24 font-bold font-heading">
                    <Trans>Flexibility</Trans>
                  </h3>
                  <p className="leading-relaxed">
                    <Trans>
                      We are <strong>adaptable</strong> and open to change
                      because we are remote-first and globally connected. We are{" "}
                      <strong>responsible</strong> for managing our time, our
                      projects, and our results.
                    </Trans>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Founders;
