import * as React from "react";
import HeaderAndMeta from "../components/HeaderAndMeta";
import Landing from "../components/Landing";
import Products from "../components/Products";
import Investors from "../components/Investors";
import Founders from "../components/Founders";
import Careers from "../components/Careers";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import { graphql } from "gatsby";

const IndexPage = () => {
  return (
    <>
      <main>
        <HeaderAndMeta />
        <div>
          <Landing />
          <Products />
          <Investors />
          <Founders />
          <Careers />
          <Contact />
        </div>
        <Footer />
      </main>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
