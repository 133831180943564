import * as React from "react";
import { Trans } from "gatsby-plugin-react-i18next";

const Products = () => {
  return (
    <>
      <section
        id="products"
        className="tl-wrapper relative text-navy-blue overflow-hidden"
      >
        <div className="pt-skew absolute top-0 left-0 right-0 bottom-0">
          <div className="bg-grey-founders w-full h-full"></div>
        </div>
        <div className="bg-top-left pt-skew"></div>
        <div className="relative pt-skew">
          <div className="container flex">
            <div className="w-full">
              <p className="subheading">
                <Trans>
                  We help build several ecosystems by taking part in
                  contributing to decentralized open source projects.
                </Trans>
              </p>
              <h2 className="heading-2">
                <Trans>What We Are Working On</Trans>
                <span></span>
              </h2>
              <ul className="mt-40 flex flex-wrap -m-12">
                <li className="p-12 w-full lg:w-1/3">
                  <div className="card h-full animation-delay-300">
                    <div className="w-full md:h-168 md:w-auto lg:h-auto shrink-0">
                      <a
                        href="https://shinkai.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <picture>
                          <source
                            srcSet="/images/p-shinkai@2x.webp 2x, /images/p-shinkai.webp 1x"
                            type="image/webp"
                          />
                          <source
                            srcSet="/images/p-shinkai@2x.png 2x, /images/p-shinkai.png 1x"
                            type="image/png"
                          />
                          <img
                            className="w-full h-full object-contain"
                            width="360"
                            height="240"
                            src="/images/p-shinkai.png"
                            alt="Shinkai"
                          />
                        </picture>
                      </a>
                    </div>
                    <div className="flex h-full flex-col mt-25 md:ml-30 md:mt-0 lg:ml-0 lg:mt-25">
                      <a
                        href="https://shinkai.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p className="text-24 font-bold font-heading">Shinkai Network</p>
                      </a>
                      <p className="mt-17 mb-30 lg:mb-36 tracking-wide leading-6 md:leading-7">
                        <Trans>
                          An open-source AI Agent OS built from the ground-up
                          that allows private peer-to-peer communication between agents
                          with native integration with Web3, allowing to build a
                          new AI-centric version of the internet and decentralized worlds with
                          complex inter-AI interactions 
                        </Trans>
                        <div style={{ marginTop: '10px', marginBottom: '10px', width: '100%', height: '2px', backgroundColor: '#E9EBED' }} />
                        <Trans>
                          dcSpark is a core contributor. Project is owned by the Shinkai Foundation
                        </Trans>
                      </p>
                    </div>
                  </div>
                </li>
                <li className="p-12 w-full lg:w-1/3">
                  <div className="card h-full animation-delay-600">
                    <div className="w-full md:h-168 md:w-auto lg:h-auto shrink-0">
                      <a
                        href="https://zeko.io"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <picture>
                          <source
                            srcSet="/images/p-zeko@2x.webp 2x, /images/p-zeko.webp 1x"
                            type="image/webp"
                          />
                          <source
                            srcSet="/images/p-zeko@2x.png 2x, /images/p-zeko.png 1x"
                            type="image/png"
                          />
                          <img
                            className="w-full h-full object-contain"
                            width="360"
                            height="240"
                            src="/images/p-zeko.png"
                            alt="Zeko"
                          />
                        </picture>
                      </a>
                    </div>
                    <div className="flex h-full flex-col mt-25 md:ml-30 md:mt-0 lg:ml-0 lg:mt-25">
                      <a
                        href="https://zeko.io"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p className="text-24 font-bold font-heading">
                          Zeko
                        </p>
                      </a>
                      <p className="mt-17 mb-30 lg:mb-36 tracking-wide leading-6 md:leading-7">
                        <Trans>
                          A ZK rollup framework that allows for provable private computation,
                          allowing to spin up new computational environments, and then proving
                          the state of that environment to the outside world. Usable for
                          finance, gaming, voting and more.
                        </Trans>
                        <div style={{ marginTop: '10px', marginBottom: '10px', width: '100%', height: '2px', backgroundColor: '#E9EBED' }} />
                        <Trans>
                          dcSpark contributes the core engineering and architecture. The project
                          is done in partnership with the Mina Foundation and O(1) Labs
                        </Trans>
                      </p>
                    </div>
                  </div>
                </li>
                <li className="p-12 w-full lg:w-1/3">
                  <div className="card h-full animation-delay-900">
                    <div className="w-full md:h-168 md:w-auto lg:h-auto shrink-0">
                      <a
                        href="https://www.paimastudios.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <picture>
                          <source
                            srcSet="/images/p-paima@2x.webp 2x, /images/p-paima.webp 1x"
                            type="image/webp"
                          />
                          <source
                            srcSet="/images/p-paima@2x.png 2x, /images/p-paima.png 1x"
                            type="image/png"
                          />
                          <img
                            className="w-full h-full object-contain"
                            width="360"
                            height="240"
                            src="/images/p-paima.png"
                            alt="paima"
                          />
                        </picture>
                      </a>
                    </div>
                    <div className="flex h-full flex-col mt-25 md:ml-30 md:mt-0 lg:ml-0 lg:mt-25">
                      <a
                        href="https://www.paimastudios.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p className="text-24 font-bold font-heading">
                          Paima Engine
                        </p>
                      </a>
                      <p className="mt-17 mb-30 lg:mb-36 tracking-wide leading-6 md:leading-7">
                        <Trans>
                          World's most advance framework for creating autonomous worlds
                          and onchain games, supporting all major blockchains
                          as the world's premier modular gaming stack. Supports
                          building fully decentralized worlds in just days using simple
                          Web2 skills like Javascript
                        </Trans>
                        <div style={{ marginTop: '10px', marginBottom: '10px', width: '100%', height: '2px', backgroundColor: '#E9EBED' }} />
                        <Trans>
                          dcSpark builds infra to connect Paima to our other projects,
                          as well as builds user-facing applications that leverage Paima
                        </Trans>
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Products;
