import * as React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import classNames from "classnames";

const Contact = () => {
  const { t } = useTranslation();
  const recaptchaRef = React.useRef<any>(null);
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [isSuccess, setSuccess] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();
    const captchaToken = await recaptchaRef.current.getValue();

    const bodyObj = {
      email: name + " <" + email + ">",
      message: message,
    };

    try {
      setLoading(true);
      setSuccess(false);
      await fetch("https://contact-service.dcspark.io", {
        headers: {
          "Content-Type": "application/json",
        },
        mode: "no-cors", // 'cors' by default
        method: "POST",
        body: JSON.stringify({
          ...bodyObj,
          "captcha-token": captchaToken,
        }),
      });
      setName("");
      setEmail("");
      setMessage("");
      setSuccess(true);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      recaptchaRef.current.reset();
      setIsSubmitEnabled(false);
    }
  };

  return (
    <>
      <section id="contact" className="tl-wrapper relative text-navy-blue">
        <div className="pt-skew absolute top-0 left-0 right-0 bottom-0">
          <div className="bg-grey-founders w-full h-full"></div>
        </div>
        <div className="bg-top-left pt-skew"></div>
        <div className="relative pt-skew">
          <div className="container flex relative overflow-visible">
            <div className="w-full text-center">
              <p className="subheading">
                <Trans>Have a question or just want to say hi?</Trans>
              </p>
              <h2 className="heading-2">
                <Trans>Contact</Trans>
                <span></span>
              </h2>
              <div className="contact-wrapper mt-50 mx-auto relative z-[12]">
                <form name="contact" onSubmit={handleSubmit} className="mb-24">
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="flex flex-col md:flex-row w-full">
                    <div className="mb-20 md:mr-25 md:mb-0 w-full">
                      <label className="hidden"></label>
                      <input
                        className="input-custom"
                        type="text"
                        name="name"
                        id="name"
                        placeholder={t("Your full name *")}
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="w-full">
                      <label className="hidden"></label>
                      <input
                        className="input-custom"
                        type="email"
                        name="mail"
                        id="mail"
                        placeholder={t("Your email address *")}
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <textarea
                    name="message"
                    rows={5}
                    className="w-full mt-20 md:mt-25 px-25 py-20 input-custom"
                    placeholder={t("Message *")}
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                  <div className="g-recaptcha">
                    <ReCAPTCHA
                      className="block"
                      ref={recaptchaRef}
                      sitekey={"6LexZ-ghAAAAAJxFUctUaWFJ1WlqG3l7oK0lBJsK"}
                      onChange={() => setIsSubmitEnabled(true)}
                      onExpired={() => setIsSubmitEnabled(false)}
                      onErrored={() => setIsSubmitEnabled(false)}
                    />
                  </div>
                  <button
                    className={classNames(
                      "btn-big w-full mt-20 bg-grey-p-btn rounded-full transition disabled:bg-gray-400",
                    )}
                    type="submit"
                    disabled={!isSubmitEnabled || !name || !email || !message}
                  >
                    {isLoading ? <Spinner /> : <Trans>Submit</Trans>}
                  </button>
                </form>
                {isSuccess ? (
                  <div className="flex items-start h-[40px]">
                    <img
                      src="/images/icons/check-icon.svg"
                      className="h-20 w-20"
                      alt=""
                    />
                    <p className="text-primary-color-900 font-base text-14 pl-6">
                      <Trans>
                        Thank you! We've received your message. Someone from out
                        team will contact you soon.
                      </Trans>
                    </p>
                  </div>
                ) : (
                  <div className="h-[40px]" />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;

const Spinner = () => (
  <svg
    role="status"
    className="inline h-24 w-24 animate-spin fill-white text-[#e5e7eb]"
    viewBox="0 0 100 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
      fill="currentColor"
    />
    <path
      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
      fill="currentFill"
    />
  </svg>
);
